import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Channel } from '../../../admin/channels/channel';
import { WebPlayerState } from '../../web-player-state.service';
import { Router } from '@angular/router';
@Component({
    selector: 'channel-show',
    templateUrl: './channel-show.component.html',
    styleUrls: ['./channel-show.component.scss'],
})
export class ChannelShowComponent implements OnInit {
    isGenrePage: boolean = false;
    @Input() set channel(channel: Channel) {
        this.channel$.next(channel);
    }
    @Input() queney = false;


    public channel$ = new BehaviorSubject<Channel>(null);

    constructor(private route: ActivatedRoute, private state: WebPlayerState, private router: Router,
    ) { }

    ngOnInit() {
        this.isGenrePage = this.router.url.includes('genre');
        this.state.scrollContainer.nativeElement.scrollTop = 0;
        this.route.data.subscribe(data => {
            if (data.api && data.api.channel) {
                this.channel$.next(data.api.channel);
            }
            debugger;
            if (data?.queney) {
                this.queney = !!data.queney;
            }
        });
    }
}
