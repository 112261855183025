import {Injectable} from '@angular/core';
import {Track, TRACK_MODEL} from '../../../models/Track';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Artist, ARTIST_MODEL} from '../../../models/Artist';
import {Album, ALBUM_MODEL} from '../../../models/Album';
import {HttpCacheClient} from '@common/core/http/http-cache-client';
import {tap} from 'rxjs/operators';
import {CurrentUser} from '@common/auth/current-user';

export type Likeable = Artist | Album | Track;

interface AllLikes {
    [key: string]: { [key: number]: true };
}

@Injectable({
    providedIn: 'root'
})
export class UserLibrary {
    private likes: AllLikes = {
        [TRACK_MODEL]: {},
        [ALBUM_MODEL]: {},
        [ARTIST_MODEL]: {},
    };

    constructor(
        private http: HttpCacheClient,
        private currentUser: CurrentUser,
    ) {
    }

    public setLikes(likeables: AllLikes) {
        this.likes = {...this.likes, ...likeables};
    }

    public add(likeables: Likeable[]): Promise<Likeable[]> {
        return new Promise((resolve, reject) => {
            const payload = likeables
                .filter(likeable => !this.has(likeable))
                .map(likeable => {
                    return {likeable_id: likeable.id, likeable_type: likeable.model_type};
                });
            this.http.post('users/me/add-to-library', {likeables: payload})
                .pipe(tap(
                    async (res) => {
                        payload.forEach((item) => {
                            switch (item.likeable_type) {
                                case 'album':
                                    this.http.clearKey('users/me/liked-albums');
                                    return;
                                case 'artist':
                                    this.http.clearKey('users/me/liked-artists');
                                    return;
                                case 'track':
                                    this.http.clearKey('users/me/liked-tracks');
                                    return;
                            }
                        });

                    }
                ))
                .subscribe(() => {
                    payload.forEach(like => {
                        if (!this.likes[like.likeable_type]) {
                            this.likes[like.likeable_type] = {};
                        }
                        this.likes[like.likeable_type][like.likeable_id] = true;
                    });
                    resolve(likeables);
                }, () => reject());
        });
    }

    public remove(likeables: Likeable[]) {
        return new Promise(((resolve, reject) => {
            const payload = likeables.map(likeable => {
                return {likeable_id: likeable.id, likeable_type: likeable.model_type};
            });
            this.http.delete('users/me/remove-from-library', {likeables: payload}).pipe(tap(
                async (res) => {
                    await this.http.clearKey('users/me/liked-tracks');
                }
            )).subscribe(() => {
                payload.forEach(like => {
                    delete this.likes[like.likeable_type][like.likeable_id];
                });
                resolve(likeables);
            }, () => reject());
        }));
    }

    public has(likeable: Likeable): boolean {
        return this.likes[likeable.model_type] &&
            this.likes[likeable.model_type][likeable.id];
    }

    public count(modelType: string): number {
        return Object.keys(this.likes[modelType]).length;
    }
}
