import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialAuthService } from '../social-auth.service';
import { CurrentUser } from '../current-user';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { Toast } from '../../core/ui/toast.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '@common/core/ui/custom-menu/menu-item';
import { slugifyString } from '@common/core/utils/slugify-string';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { filter } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { User } from '@common/core/types/models/User';

@Component({
  selector: 'register-by-phone',
  templateUrl: './register-by-phone.component.html',
  styleUrls: ['./register-by-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterByPhoneComponent implements OnInit {
  public loading$ = new BehaviorSubject<boolean>(false);
  public registerPolicies: Partial<MenuItem>[] = [];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  errors: boolean = false;
  public errResponse$ = new BehaviorSubject<Partial<User>>({});
  @ViewChild('stepper', { static: false }) stepper: MatStepper;


  constructor(
    public auth: AuthService,
    public socialAuth: SocialAuthService,
    public settings: Settings,
    public route: ActivatedRoute,
    private user: CurrentUser,
    private router: Router,
    private toast: Toast,
    private bootstrapper: Bootstrapper,
    private recaptcha: RecaptchaService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    if (this.recaptcha.enabledFor('registration')) {
      this.recaptcha.load();
    }
    this.firstFormGroup = this.fb.group({
      phonenumber: ['', Validators.required]
    });
    this.secondFormGroup = this.fb.group({
      verifcode: ['', Validators.required]
    });
  }
  SendCode() {
    debugger
    this.loading$.next(true);
    this.auth.subscribe(this.firstFormGroup.value.phonenumber.toString()).subscribe(response => {
      if (response.status == 'success') {
        this.loading$.next(false);
        this.toast.open(response.message, { duration: 12000 });
        this.stepper.next();
      } else {
        this.toast.open(response.message, { duration: 12000 });
      }
    }, (errResponse: BackendErrorResponse) => {
      this.errResponse$.next(errResponse.errors);
      this.toast.open(errResponse.message, { duration: 12000 });
      this.loading$.next(false);
    });
  }
  confirm() {
    this.loading$.next(true);
    this.auth.confirm(this.firstFormGroup.value.phonenumber.toString(), this.secondFormGroup.value.verifcode).subscribe(response => {
      if (response.status == 'success') {
        this.router.navigate(['/login']).then(() => {
          this.loading$.next(false);
          this.toast.open('Registred !, Please enter login', { duration: 12000 });
        });

      } else {
        this.toast.open(response.message, { duration: 12000 });
        this.loading$.next(false);
      }
    }, (errResponse: BackendErrorResponse) => {
      this.toast.open(errResponse.message, { duration: 12000 });
      this.loading$.next(false);
    });
  }

}
