<ng-container *ngIf="datatable.paginator.paginatedOnce$ | async">
    <header>
        <!-- <h1>{{ (totalPlaylists$ | async)}} <span trans>Playlists</span></h1> -->
        <div class="right-column">
            <datatable-header-search-input placeholder="Search within playlists..." [hideIcon]="true">
            </datatable-header-search-input>
        </div>
    </header>

    <ad-host slot="ads.general_top" class="margin-bottom"></ad-host>
    <div class="qsp-album-channel">
        <media-grid>
            <playlist-item *ngFor="let playlist of datatable.data$ | async; trackBy: trackByFn" [playlist]="playlist"
                [contextMenu]="{item: playlist, type: 'playlist'}"></playlist-item>
        </media-grid>
    </div>
</ng-container>

<loading-indicator [isVisible]="datatable.paginator.loading$ | async" class="inline"></loading-indicator>

<div class="no-results-message" *ngIf="datatable.paginator.noResults$ | async">
    <mat-icon class="icon" svgIcon="album"></mat-icon>
    <div class="text">
        <div class="main" trans>Nothing to display.</div>
        <div class="secondary" trans>You have not added any playlists to your library yet.</div>
    </div>
</div>