import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Player } from '../player.service';
import { FullscreenOverlay } from '../../fullscreen-overlay/fullscreen-overlay.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { NavSidebarUserMenuComponent } from '../../nav-sidebar/nav-sidebar-user-menu/nav-sidebar-user-menu.component';
import {DefaultImagePaths} from "../../default-image-paths.enum";

@Component({
    selector: 'mobile-player-controls',
    templateUrl: './mobile-player-controls.component.html',
    styleUrls: ['./mobile-player-controls.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MobilePlayerControlsComponent implements OnDestroy {
    private subscription: Subscription;
    constructor(
        public player: Player,
        public overlay: FullscreenOverlay,
        private matSheet: MatBottomSheet,
    ) { }

    openUserMenuSheet() {
        this.subscription?.unsubscribe();
        this.subscription = this.matSheet.open(NavSidebarUserMenuComponent).instance.loggedInUserMenu.itemClicked.subscribe(() => {
            this.matSheet.dismiss();
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        console.log("player", this.player.getCuedTrack())
    }


    get trackImage(){
        if(this.player?.getCuedTrack()?.album?.image){
            return this.player?.getCuedTrack()?.album?.image
        }
        if(this.player?.getCuedTrack()?.image){
            return  this.player?.getCuedTrack()?.image
        }
        if(  this.player?.getCuedTrack()?.artists?.length){
            const images = this?.player?.getCuedTrack()?.artists?.map(artist => artist?.image_small)?.filter(item => !!item);
            if(images?.length && !!images[0]){
                return images[0];
            }
        }

        return DefaultImagePaths.track;
    }
}
