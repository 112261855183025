<auth-page>
    <form (ngSubmit)="login()" [formGroup]="form" *ngIf="errResponse$ | async as errors" ngNativeValidate>
        <div class="header" [transValues]="{siteName: settings.get('branding.site_name')}" trans>
            {{ route.snapshot.data.message || 'Sign in to your account' }}
        </div>

        <div class="many-inputs">
            <div class="input-container">
                <label for="login-phonenumber" trans>Phone Number</label>
                <input type="text" formControlName="phoneNumber" id="login-phonenumber" required>
                <p class="error big-error" *ngIf="errors.phoneNumber">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.phoneNumber}}</span>
                </p>
            </div>

            <div class="input-container">
                <div class="label-with-action">
                    <label for="login-password" trans>Password</label>
                    <a class="forgot-password" [routerLink]="['/forgot-password']" tabindex="-1" trans>Forgot your
                        password?</a>
                </div>
                <input type="password" formControlName="code" id="login-password" required>
                <p class="error big-error" *ngIf="errors.code">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.code}}</span>
                </p>
            </div>
        </div>

        <mat-checkbox name="remember-me" class="remember-me" color="accent" formControlName="remember" trans>Stay signed
            in for a month</mat-checkbox>

        <button mat-raised-button class="primary-button" color="accent" type="submit" [disabled]="(loading$ | async) || (form.invalid)"
            trans>Continue</button>
    </form>
</auth-page>