import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'create-phone-subscription-panel',
  templateUrl: './create-phone-subscription-panel.component.html',
  styleUrls: ['./create-phone-subscription-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePhoneSubscriptionPanelComponent implements OnInit {
  @Input() submitText = 'Send verification code';
  @Input() submitVerifText = 'Submit verification code';
  @Input() showSidebar = false;
  @Output() selected = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
