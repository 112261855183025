import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {HttpErrorHandler} from './errors/http-error-handler.service';
import {AppHttpClient} from './app-http-client.service';
import {Storage} from "@ionic/storage-angular";

@Injectable({
    providedIn: 'root'
})
export class HttpCacheClient extends AppHttpClient {

    static dbKey = 'qsp_cache';
    static cacheLifetime = 60 * 60 * 1000;


    protected cache: any = {
        isInstalled: false
    };
    private initialized = false;

    constructor(
        protected http: HttpClient,
        protected errorHandler: HttpErrorHandler,
        private storage: Storage
    ) {

        super(http, errorHandler);

    }


    public async init() {
        if (this.initialized) {
            return;
        }
        try {
            await this.storage.create();
            this.cache = {
                ...await this.storage.get(HttpCacheClient.dbKey),
                installed : true
            } ?? {
                isInstalled: false
            };
            console.log('current cache', this.cache);

        } catch (e) {
            console.log('cannot load cache from db', e);

        }
    }


    private async saveCache() {
        try {
            this.cache.isInstalled = true;
            await this.storage.set(HttpCacheClient.dbKey, this.cache);
        } catch (e) {
            console.log('cannot save cache from db', e);
        }
    }

    public getWithCache<T>(url: string, params: object = {}, options: object = {}): Observable<T> {
        let key = this.makeCacheKey(url, params);
        const isBootstrap = url.startsWith('bootstrap-data');
        const hasToken = !!localStorage.getItem('auth-token');
        console.log('hasToken', {hasToken});
        if (isBootstrap && hasToken && this.cache.isInstalled) {
            key = key + '-authenticated';
        }
        // this request is in progress and not yet resolved,
        // we can return same observable for all callers
        if (this.cache[key] instanceof Observable) {
            return this.cache[key];

            // this request is completed and cached, we can return cached response
        } else if (this.cache[key] && !this.hasExpired(key)) {
            return of(this.cache[key]);

            // this request was not made yet, so we make a new one
        } else {
            const request = this.get(url, params, options).pipe(
                share(),
            ) as any;
            request.subscribe(async (data) => {
                this.cache[key] = data;
                if (!this.cache.time) {
                    this.cache.time = {};
                }
                this.cache.time[key] = Date.now();
                await this.saveCache();
            }, () => {
            });
            return this.cache[key] = request;
        }
    }

    public post<T>(url: string, params?: object): Observable<T> {
        // this.clearCache();
        return super.post(url, params);
    }

    public put<T>(url: string, params?: object): Observable<T> {
        // this.clearCache();
        return super.put(url, params);
    }

    public delete<T>(url: string, params?: object): Observable<T> {
        // this.clearCache();
        return super.delete(url, params);
    }

    public clearCache() {
        this.cache = {
            isInstalled : true
        };
        this.storage.remove(HttpCacheClient.dbKey).then(console.log);

    }

    private makeCacheKey(url: string, params?): string {
        return url + JSON.stringify(params);
    }

    private hasExpired(key) {
        if (!this.cache?.time || !this?.cache?.time[key]) {
            return false;
        }
        const time = this?.cache?.time[key] ?? 0;
        const currentTime = Date.now();

        try {
            const result = currentTime >= ((time * 1) + HttpCacheClient.cacheLifetime);
            console.log(`this request "${key}" has ${result ? '' : 'not'}expired`);
            return result;
        } catch (e) {
            console.log('cannot caclulate date', e);
        }

        return false;

    }


    public async clearKey(key) {
        console.log('trying to cleanup', {
            key,
            cache: this.cache,
            keys: Object.keys(this.cache)
        });
        Object.keys(this.cache).forEach(currentKey => {
            if (currentKey.startsWith(key)) {
                delete this.cache[currentKey];
                console.log('clearing', key);
            }
        });
        if (this.cache.time) {
            console.log('trying to cleanup', {
                key,
                cache: this.cache.time,
                keys: Object.keys(this.cache.time)
            });
            Object.keys(this.cache.time).forEach(currentKey => {
                if (currentKey.startsWith(key)) {
                    delete this.cache.time[currentKey];
                    console.log('clearing time', key);
                }
            });
        }
        await this.saveCache();
    }
}
