import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialAuthService } from '../social-auth.service';
import { CurrentUser } from '../current-user';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { Toast } from '../../core/ui/toast.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '@common/core/ui/custom-menu/menu-item';
import { slugifyString } from '@common/core/utils/slugify-string';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { User } from '@common/core/types/models/User';
import { filter, finalize } from 'rxjs/operators';

@Component({
  selector: 'login-by-phone',
  templateUrl: './login-by-phone.component.html',
  styleUrls: ['./login-by-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginByPhoneComponent implements OnInit {
  public loading$ = new BehaviorSubject<boolean>(false);
  errors: boolean = false;
  public errResponse$ = new BehaviorSubject<Partial<User>>({});
  public form = this.fb.group({
    phoneNumber: ['', Validators.required],
    code: ['', Validators.required],
    remember: [true],
  });
  constructor(
    public auth: AuthService,
    public socialAuth: SocialAuthService,
    public settings: Settings,
    public route: ActivatedRoute,
    private user: CurrentUser,
    private router: Router,
    private toast: Toast,
    private bootstrapper: Bootstrapper,
    private recaptcha: RecaptchaService,
    private fb: FormBuilder) { }

  ngOnInit(): void {

  }
  public login() {
    this.loading$.next(true);
    this.auth.loginWithPhone(this.form.getRawValue())
      .subscribe(response => {
        this.bootstrapper.bootstrap(response.data);
        this.router.navigate([this.auth.getRedirectUri()]).then(() => {
          this.loading$.next(false);
        });
      }, (errResponse: BackendErrorResponse) => this.handleLoginError(errResponse));
  }
  private handleLoginError(response: BackendErrorResponse) {
    this.loading$.next(false);
    console.log("response", response)
    if (response.errors?.phoneNumber === '') {
      this.toast.open('Please check your phone number.')
    }else if(response.status === 401){
        this.toast.open(response.message, {duration: 5000});
    }
    else {
      this.errResponse$.next(response.errors);
    }
  }


}
