<mat-horizontal-stepper>
    <mat-step>
        <div class="input-container" style="padding: 0 10px">
            <label trans>Your phone number</label>
            <input type="number" placeholder="Please enter your phone number" required>
        </div>
        <p class="info" trans>After clicking the button below, you will receive verification code into your mobile phone
            to
            approve the subscription.
        </p>
        <div class="actions">
            <button mat-button matStepperPrevious trans>Back</button>
            <button mat-raised-button color="accent" matStepperNext trans>
                {{submitText | trans}}
            </button>
        </div>
        <order-summary></order-summary>
    </mat-step>
    <mat-step>
        <div class="input-container" style="padding: 0 10px">
            <label trans>Verification code</label>
            <input type="number" placeholder="Please enter the verification code " required>
        </div>
        <p class="info" trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
        </p>
        <div class="actions">
            <button mat-button matStepperPrevious trans>Back</button>
            <button mat-raised-button color="accent" matStepperNext trans>
                {{submitVerifText | trans}}
            </button>
        </div>
    </mat-step>
</mat-horizontal-stepper>