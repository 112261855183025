import {PlaybackStrategy} from './playback-strategy.interface';
import {PlayerState} from '../player-state.service';
import {Track} from '../../../models/Track';
import {Injectable, NgZone} from '@angular/core';
import {PlayerQueue} from '../player-queue.service';
import {WebPlayerImagesService} from '../../web-player-images.service';
import {requestFullScreen} from '@common/core/utils/request-full-screen';
import videojs from 'video.js';

@Injectable({
    providedIn: 'root'
})
export class HlsStrategy implements PlaybackStrategy {

    /**
     * Whether player is already bootstrapped.
     */
    private bootstrapped = false;

    /**
     * Volume that should be set after player is bootstrapped.
     * Number between 1 and 100.
     */
    private pendingVolume: number = null;

    /**
     * Html5 video element instance.
     */
    private html5: HTMLVideoElement;


    private hlsPlayer: any;

    /**
     * Track currently cued for playback.
     */
    private cuedTrack: Track;

    /**
     * Track that is currently being cued.
     */
    private cueing: Track;

    constructor(
        private state: PlayerState,
        private zone: NgZone,
        private queue: PlayerQueue,
        private wpImages: WebPlayerImagesService,
    ) {
    }

    public play() {
        this.cueTrack(this.queue.getCurrent()).then(() => {
            this.hlsPlayer.play().then(() => {
                this.state.playing = true;
            }, () => {
                this.state.playing = false;
            });
        });
    }

    public pause() {
        this.hlsPlayer.pause();
        this.state.playing = false;
    }

    public stop() {
        this.pause();
        this.seekTo(0);
        this.state.playing = false;
    }

    public seekTo(time: number) {
        if (time === Infinity) return;
        this.hlsPlayer.currentTime(time);
    }

    /**
     * Get loaded track duration in seconds.
     */
    public getDuration() {
        return (this.hlsPlayer && this.hlsPlayer.duration()) > 0 ?
            this.hlsPlayer.duration() :
            0;
    }

    /**
     * Get elapsed time in seconds since the track started playing
     */
    public getCurrentTime() {
        return this.hlsPlayer ? this.hlsPlayer.currentTime() : 0;
    }

    /**
     * Set html5 player volume to float between 0 and 1.
     */
    public setVolume(volume: number) {
        if (!this.hlsPlayer) {
            this.pendingVolume = volume;
        } else {
            this.hlsPlayer.volume( volume / 100);
        }
    }

    public mute() {
        this.hlsPlayer.muted( true);
    }

    public unMute() {
        this.hlsPlayer.muted( false);
    }

    public getCuedTrack(): Track {
        return this.cuedTrack;
    }

    public isReady() {
        return this.bootstrapped;
    }

    public cueTrack(track: Track): Promise<any> {
        const cuedTrack = this.cueing || this.cuedTrack;
        if (cuedTrack && cuedTrack.id === track?.id) {
            return Promise.resolve();
        }

        this.cueing = track;

        this.state.buffering = true;

        this.bootstrap();
        this.html5.poster = this.wpImages.getTrackImage(track);

        this.hlsPlayer.src({
            src: track.url_secure,
            type: 'application/x-mpegURL',
            poster : this.wpImages.getTrackImage(track)
        });
        this.hlsPlayer.poster(this.wpImages.getTrackImage(track));
        this.cuedTrack = track;
        this.cueing = null;
        return Promise.resolve();
    }

    public maximize() {
        this.hlsPlayer.requestFullscreen();
    }

    public destroy() {
        this.html5 && this.html5.remove();
        this.html5 = null;
        if(this.hlsPlayer){
            this.hlsPlayer.dispose();
            this.hlsPlayer = null;
        }
        this.bootstrapped = false;
        this.cuedTrack = null;
    }

    private bootstrap() {
        if (this.bootstrapped) return;

        this.html5 = document.createElement('video');
        this.html5.setAttribute('playsinline', 'true');
        this.html5.setAttribute('oncontextmenu', 'return false;');
        this.html5.setAttribute('controlsList', 'nodownload');
        this.html5.id = 'hls-player';
        console.log('html5 element', this.html5);
        document.querySelector('.hls-player').appendChild(this.html5);

        this.hlsPlayer = videojs(this.html5, {
            html5: {
                vhs: {
                    overrideNative: true
                },
                nativeAudioTracks: false,
                nativeVideoTracks: false
            }
        }, () => {
            console.log('onPlayerReady', this.hlsPlayer.tech());

        });
        this.hlsPlayer.tech()?.on('audiotrackchange', e => {
            this.hlsPlayer.tech().vhs.xhr.beforeRequest = (options) => {
                console.log('xhr options', {options});
                options.headers = {
                    Authorization: localStorage.getItem('auth-token')
                };
                return options;
            };
        });
        console.log('this is the hls player', this.hlsPlayer);
        this.hlsPlayer.audioTracks().on('change', (e) => {
            console.log('on change', e);
            this.hlsPlayer.tech().vhs.xhr.beforeRequest = (options) => {
                console.log('xhr options', {options});
                options.headers = {
                    Authorization: localStorage.getItem('auth-token')
                };
                return options;
            };
        });
        this.handlePlayerReadyEvent();
        this.handlePlayerStateChangeEvents();

        this.bootstrapped = true;
    }

    private handlePlayerStateChangeEvents() {
        this.hlsPlayer.on('ended', () => {
            this.state.firePlaybackEnded();
            this.setState('playing', false);
        });

        this.hlsPlayer.on('playing', () => {
            this.setState('playing', true);
        });

        this.hlsPlayer.on('pause', () => {
            this.setState('playing', false);
        });

        this.hlsPlayer.on('error', () => {
            this.cuedTrack = null;
            this.setState('playing', false);
            this.state.firePlaybackEnded();
        });
    }

    private setState(name: string, value: boolean) {
        this.zone.run(() => this.state[name] = value);
    }

    private handlePlayerReadyEvent(resolve?) {
        if (this.state.muted) this.mute();
        this.bootstrapped = true;
        // @ts-ignore
        resolve && resolve();
        this.state.fireReadyEvent();

        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    }
}
