<header>
    <!-- <h1 trans>Listening History</h1> -->
    <!-- <header-play-button [queueId]="queueId()"></header-play-button> -->
    <div class="right-column">
        <datatable-header-search-input placeholder="Search listening history..." [hideIcon]="true"></datatable-header-search-input>
    </div>
</header>

<ad-host slot="ads.general_top" class="margin-bottom"></ad-host>

<track-table
        [showArtist]="true"
        [showAddedAt]="true"
        [showAlbum]="true"
        [queueItemId]="queueId()"
        [dataSource]="datatable" class="qsq-track-channel"
></track-table>

<loading-indicator [isVisible]="datatable.paginator.loading$ | async" class="inline"></loading-indicator>

<div class="no-results-message" *ngIf="datatable.paginator.noResults$ | async">
    <mat-icon class="icon" svgIcon="history"></mat-icon>
    <div class="text">
        <div class="main" trans>Nothing to display.</div>
        <div class="secondary" trans>You have not played any songs yet.</div>
    </div>
</div>
