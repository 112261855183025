<ng-container *ngIf="player.cued()">
    <div class="controls qsp-player-controls">
        <!-- <button class="no-style maximize" (click)="overlay.maximize()"><mat-icon svgIcon="keyboard-arrow-up"></mat-icon></button> -->
        <div class="track-info">
            <div class="img-cont" >
                <img [src]="trackImage"/>
            </div>
            <div style="width: 80%;">
                <div class="track-name" (click)="overlay.maximize()">{{player.getCuedTrack().name}}
                </div>
                <artists-links-list class="artist-name" [artists]="player.getCuedTrack()?.artists"></artists-links-list>
            </div>
        </div>
        <main-playback-buttons></main-playback-buttons>
    </div>
    <player-seekbar style="padding: 0 20px;"></player-seekbar>
</ng-container>

<custom-menu position="mobile-bottom" class="nav">
    <button type="button" class="no-style nav-item custom-menu-item" (click)="openUserMenuSheet()">
        <mat-icon svgIcon="person"></mat-icon>
        <span class="text" trans>Account</span>
    </button>
</custom-menu>

<mat-menu></mat-menu>
