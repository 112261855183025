<auth-page infoRowTarget="signin">
    <div class="header" [transValues]="{siteName: settings.get('branding.site_name')}" trans>
        {{ route.snapshot.data.message || 'Create a new account' }}
    </div>
    <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup" *ngIf="errResponse$ | async as errors" ngNativeValidate>
                <div class="many-inputs">
                    <div class="input-container">
                        <label for="phonenumber" trans>Phone Number</label>
                        <input type="number" formControlName="phonenumber" id="phonenumber" required>
                        <p class="error" *ngIf="errors.phoneNumber">
                            <mat-icon svgIcon="warning"></mat-icon>
                            <span>{{errors.phoneNumber}}</span>
                        </p>
                    </div>
                </div>
                <div>
                    <button mat-raised-button class="primary-button" color="accent" type="submit"
                        [disabled]="(loading$ | async) || (firstFormGroup.invalid)" (click)="SendCode()"
                        trans>Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
            <form [formGroup]="secondFormGroup" *ngIf="errResponse$ | async as errors" ngNativeValidate>
                <div class="many-inputs">
                    <div class="input-container">
                        <label for="email" trans>Verification code</label>
                        <input type="text" formControlName="verifcode" id="verifcode" required>
                        <p class="error" *ngIf="errors.code">
                            <mat-icon svgIcon="warning"></mat-icon>
                            <span>{{errors.code}}</span>
                        </p>
                    </div>
                </div>

                <div>
                    <button mat-raised-button class="primary-button" color="accent" [disabled]="(loading$ | async) || (secondFormGroup.invalid)" (click)="confirm()">Next</button>
                    <button mat-raised-button class="primary-button" color="default" matStepperPrevious>Back</button>
                </div>
            </form>
        </mat-step>
    </mat-horizontal-stepper>

</auth-page>