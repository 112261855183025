<ng-container *ngIf="artistPage.artist$ | async as artist">
    <button class="back-btn">
        <mat-icon svgIcon="keyboard-arrow-left" routerLink="/channel/discover"></mat-icon>
    </button>
    <artist-page-header></artist-page-header>
    <ng-container [ngSwitch]="artistPage.activeTab$ | async">
        <artist-overview-tab class="tab" *ngSwitchCase="'discography'"></artist-overview-tab>
        <similar-artists-tab class="tab" *ngSwitchCase="'similar'"></similar-artists-tab>
        <artist-bio-tab class="tab" *ngSwitchCase="'about'"></artist-bio-tab>
        <paginated-followers-tab class="tab" [loadFn]="loadMoreFollowers" *ngSwitchCase="'followers'">
            <span trans [transValues]="{displayName: artist.name}">Seems like no one is following :displayName yet.</span>
        </paginated-followers-tab>
        <paginated-track-list class="tab" *ngSwitchCase="'tracks'" [loadFn]="loadMoreArtists">
            <span trans [transValues]="{displayName: artist.name}">Follow :displayName for updates on their latest releases.</span>
        </paginated-track-list>
        <paginated-album-list class="tab" *ngSwitchCase="'albums'" [loadFn]="loadMoreAlbums">
            <span trans [transValues]="{displayName: artist.name}">Follow :displayName for updates on their latest releases.</span>
        </paginated-album-list>
    </ng-container>
    <ad-host slot="ads.artist_bottom" class="margin-top"></ad-host>
</ng-container>
