import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUser } from './current-user';
import { Toast } from '../core/ui/toast.service';
import { User } from '../core/types/models/User';
import { BehaviorSubject, Observable } from 'rxjs';
import { Settings } from '../core/config/settings.service';
import { AppHttpClient } from '../core/http/app-http-client.service';
import { BackendResponse } from '../core/types/backend-response';
import { AccessToken } from '@common/core/types/models/access-token';
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public forcedEmail$ = new BehaviorSubject(null);
    public endpoint = "https://warap-int.queney.co";
    constructor(
        protected httpClient: AppHttpClient,
        protected currentUser: CurrentUser,
        protected router: Router,
        protected route: ActivatedRoute,
        protected toast: Toast,
        protected zone: NgZone,
        protected config: Settings,
    ) { }

    public login(credentials: object): BackendResponse<{ data: string }> {
        return this.httpClient.post('auth/login', credentials).pipe(
            tap(
                async (data: any) => {
                    const token = data?.token;
                    if (token) {
                        localStorage.setItem('auth-token', token);
                    }
                }
            )
        );
    }

    public register(credentials: object): BackendResponse<{ bootstrapData?: string, message?: string }> {
        return this.httpClient.post('auth/register', credentials);
    }

    public logOut() {
        this.httpClient.post('auth/logout')
            .pipe(
                tap(
                    async (data: any) => {
                        const token = data?.token;
                        if (token) {
                            localStorage.removeItem('auth-token');
                        }
                    }
                )
            ).subscribe(() => {
            this.currentUser.assignCurrent();
            this.router.navigate(['/login']);
        });
    }

    public sendPasswordResetLink(credentials: object): Observable<{ data: string }> {
        return this.httpClient.post('auth/password/email', credentials);
    }

    public resetPassword(credentials: object): Observable<{ data: User }> {
        return this.httpClient.post('auth/password/reset', credentials);
    }

    public resendEmailConfirmation(email: string): BackendResponse<void> {
        return this.httpClient.post('auth/email/verify/resend', { email });
    }

    public revokeAccessToken(tokenId: number): BackendResponse<void> {
        return this.httpClient.delete('access-tokens/' + tokenId);
    }

    public createAccessToken(tokenName: string): BackendResponse<{ token: AccessToken, plainTextToken: string }> {
        return this.httpClient.post('access-tokens', { tokenName });
    }

    /**
     * Get URI user should be redirect to after login.
     */
    public getRedirectUri(): string {
        if (this.currentUser.redirectUri) {
            const redirectUri = this.currentUser.redirectUri;
            this.currentUser.redirectUri = null;
            return redirectUri;
        } else if (this.currentUser.isAdmin()) {
            return this.config.get('vebto.auth.adminRedirectUri');
        } else {
            return this.config.get('vebto.auth.redirectUri');
        }
    }
    public loginWithPhone(credentials: object): BackendResponse<{ data: string }> {
        return this.httpClient.post(`mobile-gateway/login`, credentials);
    }
    public subscribe(number: number): BackendResponse<{ bootstrapData?: string, message?: string }> {
        return this.httpClient.post(`mobile-gateway/pre-register`, { phoneNumber: number })
    }
    public confirm(number: number, pin: number): BackendResponse<{ bootstrapData?: string, message?: string }> {
        return this.httpClient.post(`mobile-gateway/register`, { phoneNumber: number, code: pin })
    }
}
